import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutMeQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='About' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='About Me'
            subheader={
              <span>
                <em>You are what you do, not what you say you'll do.</em>
              </span>
            }
          />
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section>
                <Text variant='p'>
                  Hi, I'm a self-taught developer made in Greece, based in
                  Orange County, CA. I've always been in enamored of technology,
                  and code. I'm also passionate about teaching and making the
                  world a better place, one line of code at a time. I enjoy
                  photography, cinematography and good design. I'm a sleight of
                  hand magician, so you'll always catch me carrying a deck of
                  cards with me, anywhere I go. I've been known to be the
                  world’s fastest Rubik’s cube solver at 42.7s{' '}
                  <em>(well, maybe not world’s fastest)</em>.
                </Text>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutMeQuery = graphql`
  query AboutMeQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`